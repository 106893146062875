import './home.css'
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import filterButton from '../../assets/filterButton.svg'
import Stack from '@mui/material/Stack';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCategories, getCities, getCountries, getProvinces, getSubCategories } from '../../services/filter';
import Header from '../../components/header/header';
import { FILTER, SEARCH } from '../../constants/Constant';
import {XlviLoaderComponent} from '../../components/Loaders/loaders.js'

const Tagline = () => {
    return (
        <Stack direction='vertical'>
            <div >
                <p className='first-text-home'><span className='gradient-text'>Transform Clicks Into Clients</span></p>
                <p className='second-text-home'>Your Ultimate Gateway to Quality Leads</p>
            </div>
        </Stack>
    )
}

const SearchBar = ({ searchQuery, handleChange, setIsVisibleFunc, isVisible }) => {
    return (
        <div className='search-component'>
            <div className="search-container">
                <input type="text" className="search-input" placeholder="Search Your Leads Here..."
                    name='searchQuery'
                    value={searchQuery}
                    disabled={isVisible}
                    onChange={handleChange} />
                <img src={filterButton} alt="Logo with title" className='filter-logo' onClick={setIsVisibleFunc} />
            </div>
        </div>
    );
};

const SearchButton = ({ handleButtonClick }) => {
    return (
        <div>
            <button className="search-button" onClick={handleButtonClick}>Submit</button>
        </div>
    )
}

const Home = () => {

    const [isVisible, setIsVisible] = useState(true);
    const [countries, setCountries] = useState([]);
    const [provinces, setProvinces] = useState([]);
    const [cities, setCities] = useState([]);
    const [categories, setCategories] = useState([]);
    const [subcategories, setSubcategories] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState("");
    const [selectedProvince, setSelectedProvince] = useState("");
    const [selectedCity, setSelectedCity] = useState("");
    const [selectedCategory, setSelectedCategory] = useState("");
    const [selectedSubcategory, setSelectedSubcategory] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();

    const handleButtonClick = () => {
        var data = {};
        if (isVisible) {
            data = {
                country: selectedCountry,
                province: selectedProvince,
                city: selectedCity,
                subcategories: [selectedSubcategory],
                type : FILTER
            }
        }
        else {
            data = {
                searchQuery: searchQuery.toLowerCase(),
                type : SEARCH
            }
        }
        navigate('/lead', {
            state: { input: data }
        });
    };

    const setIsVisibleFunc = () => {
        setIsVisible(!isVisible)
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        if (name === "country") {
            setSelectedCountry(value);
            getProvincesData(value);
        }
        else if (name === "province") {
            setSelectedProvince(value);
            getCitiesData(selectedCountry, value);
        }
        else if (name === "city") {
            setSelectedCity(value);
        }
        else if (name === "category") {
            setSelectedCategory(value)
            getSubcategoriesData(value);
        }
        else if (name === "subcategory") {
            setSelectedSubcategory(value);
        }
        else if (name === "searchQuery") {
            console.log(value);
            setSearchQuery(value);
        }
    };

    const getCountriesData = () => {
        getCountries().then((res) => {
            setCountries(res.data)
            setLoading(false);
        })
    }

    const getProvincesData = (country) => {
        getProvinces(country).then((res) => {
            setProvinces(res.data)
        })
    }

    const getCitiesData = (country, province) => {
        getCities(country, province).then((res) => {
            setCities(res.data)
        })
    }

    const getCategoriesData = () => {
        getCategories().then((res) => {
            setCategories(res.data)
        })
    }

    const getSubcategoriesData = (category) => {
        getSubCategories(category).then((res) => {
            setSubcategories(res.data)
        })
    }

    useEffect(() => {
        getCategoriesData();
        getCountriesData();
    }, []);

    const FilterOptions = () => {
        return (
            <div className="container">
                <div className="section">
                    <InputLabel className="section-title">Select Location</InputLabel>
                    <FormControl className="full-width">
                        <InputLabel id="country" className='section-title'>Country</InputLabel>
                        <Select
                            className='select-option'
                            name='country'
                            onChange={handleChange}
                            value={selectedCountry}>
                            {countries.map((option, index) => (
                                <MenuItem key={index} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <div className="row">
                        <FormControl className="full-width">
                            <InputLabel className='section-title'>State</InputLabel>
                            <Select
                                className='select-option'
                                name='province'
                                onChange={handleChange}
                                value={selectedProvince}>
                                {provinces.map((option, index) => (
                                    <MenuItem key={index} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl className="full-width">
                            <InputLabel className='section-title'>City</InputLabel>
                            <Select
                                className='select-option'
                                name='city'
                                onChange={handleChange}
                                value={selectedCity}>
                                {cities.map((option, index) => (
                                    <MenuItem key={index} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                </div>

                <div className="section">
                    <InputLabel className="section-title">Select Category</InputLabel>
                    <FormControl className="full-width">
                        <InputLabel className='section-title'>Category</InputLabel>
                        <Select
                            className='select-option'
                            name='category'
                            onChange={handleChange}
                            value={selectedCategory}>
                            {categories.map((option, index) => (
                                <MenuItem key={index} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl className="full-width">
                        <InputLabel className='section-title'>Sub-Category</InputLabel>
                        <Select
                            className='select-option'
                            name='subcategory'
                            onChange={handleChange}
                            value={selectedSubcategory}>
                            {subcategories.map((option, index) => (
                                <MenuItem key={index} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
            </div>
        );
    };

    if (loading) {
        return (
            <div>
                <XlviLoaderComponent />
            </div>
        );
    }

    return (
        <div>
            <Header />
            <div className='home-page-wrapper'>
                <div className='home-page'>
                    <Tagline />
                    <SearchBar setIsVisibleFunc={setIsVisibleFunc} handleChange={handleChange} searchQuery={searchQuery}
                        isVisible={isVisible} />
                    <div className={isVisible ? 'visible' : 'hidden'}>
                        <FilterOptions />
                    </div>
                    <SearchButton handleButtonClick={handleButtonClick} />
                </div>
            </div>
        </div>
    )
}

export default React.memo(Home)