import axios from 'axios'
import { BASE_URL } from '../constants/Constant';

const addSerial = (data , start) => {
    var count = start+1 ;
    for ( const lead of data.leads ){
        lead._id = count
        count ++ ;
    }
    return data
}


const getLeads = async (data) => {
    try {

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: BASE_URL + '/leadInfo/get/leads',
            headers: {
                "Content-Type": "application/json"
            },
            data: JSON.stringify(data)
        };

        const response = await axios.request(config);
        console.log(response)
        return addSerial(response.data , data.start)
    } catch (error) {
        return error.message
    }
}

export {
    getLeads
}