import './App.css';
import { Route, HashRouter as Router, Routes } from 'react-router-dom';
import Home from './pages/home/home.js';
import Lead from './pages/lead/lead.js';

function App() {
  return (
    <div className='main-page'>
      <Router>
        <Routes>
          <Route exact path="/" element ={ <Home/>} />
          <Route exact path="/lead" element={<Lead/>} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
