import "./header.css"
import Stack from '@mui/material/Stack';
import { useNavigate } from 'react-router-dom';

const Header = () => {

    const navigate = useNavigate();
    const handleClick = () => {
        navigate('/', {});
    }

    return (
        <Stack direction='vertical'>
            <div className='header' onClick={handleClick}>
                <p className='product-name'><span className="gradient-text">GenL</span></p>
                <p className='company-name'>By TryOuts</p>
            </div>
        </Stack>
    )
}

export default Header