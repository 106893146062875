import { BoxesLoader, XlviLoader } from "react-awesome-loaders-py3";
import { TailSpin } from "react-loader-spinner";
import "./loaders.css"
const XlviLoaderComponent = () => {
    return (
        <div className='loader-element'>
            <XlviLoader
                boxColors={["#EF4444", "#F59E0B", "#6366F1"]}
                desktopSize={"100px"}
                mobileSize={"50px"}
            />
        </div>
    );
};

const BoxesLoaderComponent = () => {
    return (
        <div className='loader-element'>
            <BoxesLoader
                boxColor={"#6366F1"}
                style={{ marginTop: "40px" }}
                desktopSize={"128px"}
                mobileSize={"80px"}
            />
        </div>
    )
}

const NormalLoader = () => {
    return (
        <div className='loader-element'>
            <p>We are generating your leads....</p>
            <TailSpin
            height="80"
            width="80"
            color="#49BBFF"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}/>
        </div>
    );
};


export {
    XlviLoaderComponent ,
    BoxesLoaderComponent,
    NormalLoader
}