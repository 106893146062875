import axios from 'axios'
import { BASE_URL } from '../constants/Constant';

const getCountries = async () => {
    try {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: BASE_URL + '/filter/get/country',
            headers: {}
        };

        const response = await axios.request(config);
        return response
    } catch (error) {
        return error.message
    }
}

const getProvinces = async (country) => {
    try {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: BASE_URL + '/filter/get/province', 
            headers: {},
            params: {
                country: country
            }
        };

        const response = await axios.request(config);
        return response
    } catch (error) {
        return error.message
    }
}

const getCities = async (country , province)=> {
    try {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: BASE_URL + '/filter/get/city', 
            headers: {},
            params: {
                country: country,
                province : province
            }
        };

        const response = await axios.request(config);
        return response
    } catch (error) {
        return error.message
    }
}

const getCategories = async () => {
    try {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: BASE_URL + '/filter/get/category',
            headers: {}
        };

        const response = await axios.request(config);
        return response
    } catch (error) {
        return error.message
    }
}

const getSubCategories = async (category) => {
    try {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: BASE_URL + '/filter/get/subcategory',
            headers: {},
            params : {
                category : category
            }
        };

        const response = await axios.request(config);
        return response
    } catch (error) {
        return error.message
    }
}

export {
    getCountries,
    getProvinces,
    getCities,
    getCategories,
    getSubCategories
}