import './lead.css'
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getLeads } from '../../services/lead';
import Header from '../../components/header/header';
import TablePagination from '@mui/material/TablePagination';
import { exportToExcel } from '../../Utils/download.js'
import DownloadIcon from '../../assets/download-icon.svg'
import { XlviLoaderComponent } from '../../components/Loaders/loaders.js'


const Table = ({ leads }) => {
    return (
        <div>
            <div className='table-wrapper'>
                <table className='lead-table' border="1">
                    <thead>
                        <tr>
                            <th className='id-column'>ID</th>
                            <th className='name-column'>Name</th>
                            <th className='address-column'>Address</th>
                            <th className='website-column'>Website</th>
                            <th className='email-column'>Email</th>
                            <th className='phone-number-column'>Phone Number</th>
                            <th className='status-column'>Status</th>
                            <th className='rating-column'>Rating</th>
                            <th className='user-count-column'>User Count</th>
                        </tr>
                    </thead>
                    <tbody>
                        {leads.map((item) => (
                            <tr key={item._id}>
                                <th className='id-column'>{item._id}</th>
                                <td className='name-column'>{item.name}</td>
                                <td className='address-column'>{item.address}</td>
                                <td className='website-column'> <a href={item.website} target="_blank" rel="noopener noreferrer">{item.website}</a></td>
                                <td className='email-column'>{item.email}</td>
                                <td className='phone-number-column'>{item.nationalPhoneNumber}</td>
                                <td className='status-column'>{item.businessStatus}</td>
                                <td className='rating-column'>{item.rating}</td>
                                <td className='user-count-column'>{item.userRatingCount}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

const TableCustomPagination = ({ count, currentPage, handleChangeRowsPerPage, handlePageChange, rowsPerPage }) => {
    return (
        <div className='custom-pagination'>
            <TablePagination
                component="div"
                showFirstButton={true}
                showLastButton={true}
                count={count}
                page={currentPage}
                onPageChange={handlePageChange}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[5 , 10 , 20 , 50]}
                onRowsPerPageChange={handleChangeRowsPerPage} />
        </div>
    )
}

const DownloadExcel = ({ input, count }) => {
    const handleDownload = () => {
        input.start = 0;
        input.end = count;
        getLeads(input).then((res) => {
            exportToExcel(res.leads, 'MyData');
        })
    };

    return (
        <div className="download-image-container">
            <div className='download-image-wrapper'>
                <img src={DownloadIcon} alt="Placeholder" className="download-image" onClick={handleDownload} />
                <div className="download-image-overlay-text">Download as Excel</div>
            </div>
            <p className='table-title'>Found {count} Leads for your query</p>
        </div>
    );
};

const Lead = () => {
    const location = useLocation();
    var data = location.state;
    const [leads, setLeads] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(0);
    const [count, setCount] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);


    const getLeadsData = async () => {
        const input = data.input
        input.start = currentPage * rowsPerPage;
        input.end = (currentPage + 1) * rowsPerPage;
        getLeads(input).then((res) => {
            setCount(res.count)
            setLeads(res.leads)
            setLoading(false)
        })
    }

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
        setLoading(true);
        setLeads([]);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
        setLoading(true);
        setLeads([]);
    };

    useEffect(() => {
        console.log("abcd");
        getLeadsData();
    }, [currentPage, rowsPerPage]);

    if (loading) {
        return (
            <div>
                <XlviLoaderComponent />
            </div>
        )
    }
    else {
        return (
            <div>
                <Header />
                <div className='lead-page'>
                    <DownloadExcel input={data.input} count={count} />
                    <Table leads={leads} />
                    <TableCustomPagination count={count} currentPage={currentPage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage} handlePageChange={handlePageChange}
                        rowsPerPage={rowsPerPage}/>
                </div>
            </div>
        )
    }
}

export default Lead